<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="sidebarVisible = true"
    >
      <feather-icon class="mr-1" icon="UsersIcon" size="21" />
      {{ $tc('Global.New') }} {{ $t('Management.Users.Users') }}
    </b-button>
    <b-sidebar
      ref="sidebar"
      shadow
      backdrop
      right
      bg-variant="white"
      width="420px"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <template #header>
        <h3 class="my-1 mx-2 font-weight-bolder">
          {{ $tc('Global.New') }} {{ $t('Management.Users.Users') }}
        </h3>
      </template>
      <validation-observer
        ref="rules"
        tag="form"
        class="px-3 mt-2"
        @submit.prevent="submit"
      >
        <b-form-group
          :label="$t('Management.Users.Firstname')"
          label-for="firstname"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Firstname')"
            rules="required"
          >
            <b-form-input
              id="firstname"
              v-model="firstname"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Users.Firstname'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.Users.Lastname')"
          label-for="lastname"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Lastname')"
            rules="required"
          >
            <b-form-input
              id="lastname"
              v-model="lastname"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Users.Lastname') })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Users.EmployeeNumber')"
          label-for="employeeNumber"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.EmployeeNumber')"
            rules="required|integer"
          >
            <b-form-input
              id="employeeNumber"
              v-model="employeeNumber"
              type="text"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Users.EmployeeNumber'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Users.Role')" label-for="role">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Role')"
            rules="required"
          >
            <v-select
              id="role"
              v-model="role"
              :placeholder="
                $t('Global.Select', { subject: $t('Management.Users.Role') })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="Roles"
            >
              <template #option="{ label }">
                <div>{{ $t(`Auth.Roles.${label}`) }}</div>
              </template>
              <template #selected-option="{ label }">
                <div>{{ $t(`Auth.Roles.${label}`) }}</div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Users.Email')" label-for="email">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Email')"
            rules="email"
          >
            <b-form-input
              id="email"
              v-model="userEmail"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Users.Email'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <template #footer>
        <div class="d-flex justify-content-between m-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="submit"
          >
            {{ $t('Global.Save') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="sidebarVisible = false"
          >
            {{ $t('Global.Retreat') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { Roles } from '@/data/enums'
import { email, integer, required } from '@validations'
import {
  BButton,
  BFormGroup,
  BFormInput,
  BSidebar,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  data: () => ({
    firstname: null,
    lastname: null,
    role: null,
    employeeNumber: null,
    userEmail: null,

    email,
    required,
    integer,
    Roles,
  }),

  methods: {
    reset() {
      this.$refs.rules.reset()
      this.dirty = false
      this.firstname = null
      this.lastname = null
      this.role = null
      this.userEmail = null
      this.employeeNumber = null
    },

    async submit() {
      const valid = await this.$refs.rules.validate()

      if (!valid) {
        this.$alert.invalid()
        return
      }

      const username =
        this.firstname.charAt(0) + this.lastname.charAt(0) + this.employeeNumber

      const user = {
        username,
        firstname: this.firstname,
        lastname: this.lastname,
        employeeNumber: parseInt(this.employeeNumber, 10),
        email: !this.userEmail ? null : this.userEmail,
        role: this.role,
        active: true,
      }

      try {
        const { data } = await this.$axios.post('users', user)

        this.$alert.create(`${data.firstname} ${data.lastname}`)
        this.reset()
        this.$emit('add', data)
        this.sidebarVisible = false
      } catch (error) {
        if (error && error.response && error.response.status === 409) {
          if (
            error.response.data.message === 'USER_ALREADY_EXISTS_BUT_IS_DELETED'
          ) {
            await this.reactiveUser(user)
            return
          }

          if (error.response.data.message === 'USER_ALREADY_EXISTS') {
            this.$alert.error(this.$t('Management.Users.EmployeeNumberExists'))
            return
          }
        }

        this.$alert.error()
      }
    },

    async reactiveUser(user) {
      try {
        const shouldReactivate = await this.shouldReactivateUser(
          `${user.firstname} ${user.lastname} (${user.username})`,
        )

        if (!shouldReactivate) {
          return
        }

        const { data } = await this.$axios.post(
          'users?is-reactivating=true',
          user,
        )

        this.$alert.create(`${data.firstname} ${data.lastname}`)
        this.reset()
        this.$emit('add', data)
        this.sidebarVisible = false
      } catch (error) {
        this.$alert.error()
      }
    },

    /**
     * Asks the user if they want to reactivate the user
     *
     * @param {string} subject
     *
     * @returns {Promise<boolean>}
     */
    shouldReactivateUser(subject) {
      try {
        const messageVNode = this.$createElement('div', {
          class: ['p-1'],
          domProps: {
            innerHTML: this.$t('Management.Employees.ReactiveUserText', {
              subject,
            }),
            style: 'font-size: 16px',
          },
        })

        return this.$bvModal.msgBoxConfirm([messageVNode], {
          title: this.$t('Management.Employees.ReactiveUserTitle'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('Global.Yes'),
          cancelTitle: this.$t('Global.Retreat'),
          hideHeaderClose: false,
          centered: true,
          'body-class': 'p-2',
        })
      } catch {
        return false
      }
    },
  },
}
</script>
